<script setup lang="ts">
import useLoadingStatus from "@/utils/loading-status";
import Card from "@/components/Card.vue";
import { ApiKey } from "@/types/api_keys";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { deleteApiKey, getApiKey } from "@/api";

import { DialogProgrammatic as dialog } from "buefy";
import i18n from "@/i18n";
import { formatDate } from "@/filters/date";

const { isLoading, whileLoading } = useLoadingStatus();
const key = ref(null as null | ApiKey);

const route = useRoute();
const router = useRouter();

async function deleteKey() {
  const id = key.value?.id;
  if (!id) {
    return;
  }

  await deleteApiKey(id as number);
  await router.push({ name: "api-keys" });
}

function showDeletePrompt() {
  dialog.confirm({
    title: i18n.t("api_keys.delete").toString(),
    message: i18n.t("api_keys.confirm_delete").toString(),
    confirmText: i18n.t("api_keys.delete").toString(),
    type: "is-danger",
    hasIcon: true,
    icon: "trash",
    onConfirm: () => deleteKey(),
  });
}

watch(
  () => route.params.id,
  () => {
    whileLoading(async () => {
      key.value = (await getApiKey(parseInt(route.params.id))).data;
    });
  },
  { immediate: true, flush: "post" }
);
</script>

<template>
  <div class="column is-half is-offset-one-quarter">
    <b-loading :is-full-page="true" :active="isLoading"></b-loading>
    <transition name="fade">
      <Card v-if="!isLoading" :title="$t('api_keys.view')">
        <template #header-actions>
          <b-button
            type="is-danger"
            icon-left="trash"
            @click="showDeletePrompt()"
            >{{ $t("api_keys.delete") }}
          </b-button>
        </template>

        <div>
          <div class="kv">
            <div class="label">ID:</div>
            <div class="val">{{ key.id }}</div>
          </div>
          <div class="kv mt-4">
            <div class="label">{{ $t("api_keys.key_id") }}:</div>
            <div class="val">{{ key.key_id }}</div>
          </div>
          <div class="kv mt-4">
            <div class="label">{{ $t("api_keys.name") }}:</div>
            <div class="val">{{ key.name }}</div>
          </div>
          <div class="kv mt-4">
            <div class="label">{{ $t("api_keys.expires_at") }}:</div>
            <div class="val">
              {{ key.expires_at ? formatDate(key.expires_at) : "-" }}
            </div>
          </div>
          <div class="kv mt-4">
            <div class="label">{{ $t("api_keys.value") }}:</div>
            <div class="val is-italic">
              {{ $t("api_keys.only_shown_when_created") }}
            </div>
          </div>
        </div>
        <template #card-footer>
          <b-button
            type="is-primary"
            @click="$router.push({ name: 'api-keys' })"
            >{{ $t("common.back") }}</b-button
          >
        </template>
      </Card>
    </transition>
  </div>
</template>

<style scoped>
.kv {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}

.label {
  width: 18rem;
  font-weight: bold;
  margin-right: 2rem;
}
</style>
